import { TakeoverAd } from 'js/components/specific/top/TakeoverAd/type';
import { EcnFrames } from 'js/const/ecnFrames';

export const TAKEOVER_AD_MAP: Record<string, TakeoverAd> = {
  // ループで処理できないため、1~4件目のみ有効とする
  rakuten_card_202408: {
    linkUrl: `/ad/9453/show/?ecnframe=${EcnFrames.TAKEOVER_AD}`,
    isTargetBlank: false,
    description: '楽天カード',
    headerBannerUrl: '/top/img/takeover/rakuten_card_202408_header.jpg',
    firstSideBannerUrl: '/top/img/takeover/rakuten_card_202408_side1.jpg',
    secondSideBannerUrl: '/top/img/takeover/rakuten_card_202408_side2.jpg',
    background: '#F9E21C',
  },
};
