import _extends from "/web/httpd_ecnavi/ecnavi/sites/ecnavi/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
var __jsx = React.createElement;
import React, { useEffect, useState } from 'react';
import { NextSeo, SiteLinksSearchBoxJsonLd } from 'next-seo';
import { request } from 'js/top/pushNotification/request';
import { Layout } from 'js/components/template/layout';
import { Top as TopPage } from 'js/components/template/top';
import { useDynamicData } from 'js/hooks/pages/useDynamicData';
import { useInformation } from 'js/hooks/pages/useInformation';
import { useTimeKeeper } from 'js/hooks/useTimeKeeper';
import 'css/top/entry.scss';
import header from '!!raw-loader!js/tag/advertisement/pc/top/header.php';
import footer from '!!raw-loader!js/tag/advertisement/pc/top/footer.php';
import { useIsLoginFromCookie } from 'js/hooks/pages/useIsLoginFromCookie';
import Error from 'pages/_error';
import { TAKEOVER_AD_MAP } from 'js/components/specific/top/TakeoverAd/const';

var Top = function Top(_ref) {
  var data = _ref.data,
      error = _ref.error;
  var dynamicData = useDynamicData();

  var _useInformation = useInformation('pc', data === null || data === void 0 ? void 0 : data.informationResponse),
      informationList = _useInformation.informationList,
      readInformation = _useInformation.readInformation;

  var _useIsLoginFromCookie = useIsLoginFromCookie(),
      isLogin = _useIsLoginFromCookie.isLogin;

  var _useState = useState(true),
      notificationProcessing = _useState[0],
      setNotificationProcessing = _useState[1]; // サイトジャック広告（検証段階のためベタ書きしている）


  var takeOverAdCodes = [{
    code: Object.keys(TAKEOVER_AD_MAP)[0]
  }, {
    code: Object.keys(TAKEOVER_AD_MAP)[1]
  }, {
    code: Object.keys(TAKEOVER_AD_MAP)[2]
  }, {
    code: Object.keys(TAKEOVER_AD_MAP)[3]
  }];
  var timeKeepers = [useTimeKeeper(takeOverAdCodes[0]), useTimeKeeper(takeOverAdCodes[1]), useTimeKeeper(takeOverAdCodes[2]), useTimeKeeper(takeOverAdCodes[3])];
  var activeIndex = timeKeepers.findIndex(function (item) {
    return item.within;
  });
  var takeoverAd = 0 <= activeIndex ? TAKEOVER_AD_MAP[takeOverAdCodes[activeIndex].code] : null;
  useEffect(function () {
    if (dynamicData !== null && dynamicData !== void 0 && dynamicData.pushNotificationRequest) {
      request(dynamicData.pushNotificationRequest.firebase_configuration, dynamicData.pushNotificationRequest.firebase_certificate, dynamicData.pushNotificationRequest.csrf_token, dynamicData.pushNotificationRequest.env);
    }
  }, [dynamicData === null || dynamicData === void 0 ? void 0 : dynamicData.pushNotificationRequest]);

  if (error) {
    return __jsx(Error, {
      statusCode: error.statusCode
    });
  }

  return __jsx(Layout, {
    isReady: typeof isLogin === 'boolean',
    hasBackground: true,
    isResponsive: true,
    hasMainContentsWrapper: false,
    isLogoH1: true,
    importantInformationList: informationList,
    readImportantInformation: readInformation,
    globalHeaderBanner: takeoverAd ? 'none' : header,
    globalFooterBanner: footer
  }, __jsx(NextSeo, {
    title: "\u30DD\u30A4\u30F3\u30C8\u30B5\u30A4\u30C8\u306E\u526F\u696D\u3067\u304A\u5C0F\u9063\u3044\u7A3C\u304E",
    description: "EC\u30CA\u30D3\u306F\u3001\u65E5\u672C\u6700\u5927\u7D1A\u306E\u30DD\u30A4\u30F3\u30C8\u30B5\u30A4\u30C8\u3067\u3059\u3002\u30CD\u30C3\u30C8\u30B7\u30E7\u30C3\u30D4\u30F3\u30B0\u3084\u30A2\u30F3\u30B1\u30FC\u30C8\u3001\u7121\u6599\u4F1A\u54E1\u767B\u9332\u3084\u30DF\u30CB\u30B2\u30FC\u30E0\u306A\u3069\u3067\u73FE\u91D1\u3084\u96FB\u5B50\u30DE\u30CD\u30FC\u3001\u30AE\u30D5\u30C8\u5238\u306A\u3069\u3068\u4EA4\u63DB\u3067\u304D\u308B\u30DD\u30A4\u30F3\u30C8\u304C\u8CB0\u3048\u307E\u3059\u3002\u526F\u696D\u3001\u526F\u53CE\u5165\u306B\u3082\u6700\u9069\u3002\u6BCE\u65E5\u30DD\u30A4\u30F3\u30C8\u3092\u30B2\u30C3\u30C8\u3057\u3066\u3001\u30AB\u30F3\u30BF\u30F3\u304A\u5C0F\u9063\u3044\u304B\u305B\u304E\u3057\u3088\u3046\uFF01",
    mobileAlternate: {
      media: 'only screen and (max-width: 640px)',
      href: 'https://ecnavi.jp/m/'
    },
    openGraph: {
      title: 'ポイントサイトの副業でお小遣い稼ぎ - ECナビ',
      description: 'ECナビは、日本最大級のポイントサイトです。ネットショッピングやアンケート、無料会員登録やミニゲームなどで現金や電子マネー、ギフト券などと交換できるポイントが貰えます。副業、副収入にも最適。毎日ポイントをゲットして、カンタンお小遣いかせぎしよう！',
      url: 'https://ecnavi.jp/',
      images: [{
        url: 'https://ecnavi.jp/global/img/ogp.png'
      }],
      type: 'website',
      siteName: 'ECナビ'
    },
    twitter: {
      site: '@ecnavi',
      cardType: 'summary_large_image'
    },
    facebook: {
      appId: '213836918724891'
    }
  }), __jsx(SiteLinksSearchBoxJsonLd, {
    name: "EC\u30CA\u30D3",
    url: "https://ecnavi.jp/",
    logo: "https://ecnavi.jp/global/img/logo_ecnavi.svg",
    potentialActions: [{
      target: 'https://ecnavi.jp/search/?word',
      queryInput: 'search_term_string'
    }]
  }), __jsx(TopPage, _extends({}, dynamicData, data === null || data === void 0 ? void 0 : data.staticDataResponse, {
    isLogin: isLogin,
    notificationProcessing: notificationProcessing,
    setNotificationProcessing: setNotificationProcessing,
    takeoverAd: takeoverAd
  })));
};

export var __N_SSP = true;
export default _withSuperJSONPage(Top);